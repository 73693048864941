@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.intro-wrp {
  // background-color: #95948a;
  height: 100%;
}
.intro-msg {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //   opacity: 0;
  transition: all 1;
  &.hidden {
    opacity: 0;
    // display: none;
  }
  .intro-msg-click {
    opacity: 0;
    animation-name: blink;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    font-size: 1rem;
    margin-top: 1rem;
  }

  h5,
  p {
    text-align: center;
  }
  h5 {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.3;
    // font-weight: bold;
    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }
    @media screen and (max-width: 370px) {
      font-size: 0.9rem;
    }
    .smaller {
      font-size: 1rem;
      @media screen and (max-width: 400px) {
        font-size: 0.9rem;
      }
      @media screen and (max-width: 370px) {
        font-size: 0.8rem;
      }
    }
  }
}

.lottie-wrp {
  z-index: 1;
  height: 100%;
  cursor: pointer;
  svg {
    cursor: pointer;
  }
}

.intro-btn-start {
  margin: 1.5rem auto;
  height: 3rem;
  width: 10rem;
  background-color: #18495b;
  color: #f5a895;
  border: none;
  border-radius: 1.5rem;
  font-size: 1.5rem;
  padding-bottom: 0.4rem;
}

.intro-btn-language-wrp {
  display: flex;
  transition: all 0.7s;
  margin-top: 5rem;
  &.hide {
    opacity: 0;
  }
}

.intro-btn-language {
  margin: 1rem 0.7rem;
  height: 3rem;
  width: 7rem;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: none;
  border-radius: 1rem;
  font-size: 1.2rem;
  padding-bottom: 0.4rem;
  z-index: 1;
  outline: none;
  font-weight: bold;
  &:hover {
    background-color: #18495b;
    color: #e3e3e3;
  }
}

.intro-loading-wrp {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background-color: #95948a;
  transition: all 1s;
  opacity: 1;
  // min-height: 40vh;
  &.hide {
    opacity: 0;
    // display: none;
  }
  .intro-loading-svg {
    transition: all 1s;
  }
}

.intro-sound-text {
  position: absolute;
  text-align: right;
  font-size: 1.2rem;
  line-height: 1.1;
  right: 3rem;
  top: 6rem;
  color: #fff;
  @media screen and (max-width: 800px) {
    font-size: 1.1rem;
  }
}

.intro-notice {
  color: #fff;
  margin: 0.5rem;
  font-size: 1.3rem;
  @media screen and (max-width: 800px) {
    font-size: 1.1rem;
  }
}

.reddot-img-wrp {
  margin-top: 3rem;
}

.reddot-img {
  width: 10rem;
}

.mini-loading-wrp {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  opacity: 1;
  &.hide {
    // opacity: 0;
    display: none;
  }
  .loading-svg {
    transition: all 1s;
  }
}
