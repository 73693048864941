@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "NanumBaReunHiPi";
  position: relative;
  height: 100%;
  width: 100%;
  animation-name: show;
  animation-duration: 3s;
  letter-spacing: 0.05rem;

  .question-inner {
    position: relative;
    width: 100%;
    // height: 850px;
    &::before {
      content: "";
      padding-top: 178%;
      float: left;
    }
  }
  .question-qna {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    // z-index: -1;
    transition: all 1s linear;
    width: 100%;
    // max-height: 100%;
    &.hide {
      opacity: 0;
    }
    &.hidden {
      opacity: 0;
      display: none;
    }
  }

  .questions-wrp {
    // width: 100%;
    position: relative;

    width: 85%;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 0rem 0rem 2rem 0rem;
    color: #ffffff;
    line-height: 1.5;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: 400px) {
      font-size: 1.2rem;
    }
    @media screen and (max-width: 350px) {
      font-size: 1.1rem;
    }
    p {
      animation-name: show;
      animation-duration: 1s;
      // animation-delay: 1s;
      animation-iteration-count: 1;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.question-msg-click {
  position: absolute;
  width: 100%;
  top: 40%;
  opacity: 0;
  animation-name: blink;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  font-size: 1rem;
  // margin-top: 2rem;
  text-align: center;
}

.answers-wrp {
  position: relative;
  height: 50%;
  width: 100%;
  padding: 2rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  // z-index: 1;
  .btn-answer {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-family: "NanumBaReunHiPi";
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0.7rem 0rem 0.9rem 0rem;
    font-size: 1.1rem;
    font-weight: bold;
    border: none;
    border-radius: 1.5rem;
    width: 85%;
    color: #404040;
    margin: 0.3rem 0;
    outline: none;
    opacity: 1;
    animation-name: show;
    animation-duration: 0.7s;
    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }
    @media screen and (max-width: 350px) {
      font-size: 0.9rem;
    }
    // transition: opacity 0.7s;
    // &.hide {
    //   opacity: 0;
    // }
  }
}
