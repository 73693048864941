.result-wrp {
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #fafafa;
  &.main {
    height: 100%;
    min-height: 770px;
    @media screen and (max-width: 400px) {
      min-height: 100%;
    }
    // @media screen and (max-width: 359px) {
    //   min-height: 760px;
    // }
  }
}

.result-inner {
  z-index: 1;
  &.main {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    justify-content: center;
    align-items: center;
    &.input-name {
      height: 700px;
      width: 19rem;
      p {
        font-size: 1.5rem;
        margin: 0.5rem;
        color: #6a6a6a;
      }
      input {
        width: 100%;
        text-align: center;
        height: 2rem;
        font-size: 1.5rem;
        font-family: "NanumBaReunHiPi";
      }
    }
  }
  &.color {
    padding-bottom: 1rem;
  }
}

.result-inner-question {
  font-size: 1.5rem;
  line-height: 1.3;
  letter-spacing: 0.01rem;
  @media screen and (max-width: 395px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 359px) {
    font-size: 1.3rem;
  }
}

.result-inner-text {
  font-size: 1.3rem;
  line-height: 1.3;
  letter-spacing: 0.01rem;
  @media screen and (max-width: 395px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 359px) {
    font-size: 1.1rem;
  }
}

.result-outline {
  position: absolute;
  top: 0;
  left: 0;
  // z-index: -1;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  &.main {
    height: 100%;
    opacity: 0.3;
  }
}

.result-main-img-wrp {
  width: 200px;
  @media screen and (max-width: 360px) {
    width: 180px;
  }
}

.result-main-img {
  opacity: 1;
  &.loading {
    opacity: 0;
  }
}

.btn-sound.result {
  top: 2.5rem;
  right: 2rem;
  font-size: 1.5rem;
}

.result-main-paragraph {
  margin: 1rem 0;
  color: #6a6a6a;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.2;
  @media screen and (max-width: 440px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 395px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 359px) {
    font-size: 0.8rem;
  }
}

.result-top {
  position: relative;
  //   height: 200vw;
  width: 100%;
  padding: 2rem;
}

.result-input-name {
  height: 2rem;
  border: none;
  border-bottom: 1px solid black;
  background-color: #fafafa;
}

.result-username {
  letter-spacing: 0.2rem;
}

.result-img {
  width: 95%;
}

.result-btn-save {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 3rem;
  color: #ffffff;
  border: none;
  font-size: 1.3rem;
  border-radius: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  padding-bottom: 0.2rem;
}

.result-btn-view {
  width: 100%;
  height: 3rem;
  margin: 1rem;
  cursor: pointer;
  z-index: 1;
  background-color: #cfcfcf;
  color: #6a6a6a;
  border: none;
  border-radius: 1.5rem;
  font-size: 1.5rem;
  padding-bottom: 0.4rem;
  font-weight: bold;
}

.result-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 3.5rem;
  margin: 0.8rem;
  &.center {
    justify-content: center;
  }
  .result-icons-right {
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.5rem;
    }
  }
  &.contact {
    margin: 1.2rem 0 0.6rem 0;
    font-size: 1.5rem;
    @media screen and (max-width: 375px) {
      font-size: 1.3rem;
    }
    @media screen and (max-width: 340px) {
      font-size: 1.1rem;
    }

    p {
      padding-bottom: 0.3rem;
    }
  }
  &.center {
    justify-content: center;
  }
}

.result-contact-email {
  border: 1rem;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.5rem;
  padding: 1rem;
  font-weight: normal;
  cursor: pointer;
  p {
    margin-bottom: 0.2rem;
  }
}
.result-divider {
  width: calc(95% - 2rem);
  // border-top: 1px dotted rgba(106, 106, 106, 0.4);
  // border-width: 0.3rem;
  margin-top: -0.5rem;
  display: flex;
  justify-content: center;
  color: #6a6a6a;
  opacity: 40%;
  letter-spacing: 0.7rem;
  font-size: 1.5rem;
  overflow: hidden;
  font-weight: bold;
  &::after {
    content: ".............................";
  }
}

.btn-gohome {
  cursor: pointer;
}

.result-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  // opacity: 70%;
  color: #6a6a6a;
  padding: 1rem;
  font-size: 1.2rem;
  text-align: center;
  .result-popup-btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    color: #6a6a6a;
    font-size: 1.5rem;
    border: none;
    outline: none;
  }
  h4 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
  h5 {
    text-align: left;
    text-align: center;
    margin: 0.5rem;
  }
  ul {
    font-weight: normal;
    text-align: left;
  }
  li {
    margin: 0.7rem;
    line-height: 1.3;
    letter-spacing: 0.01rem;
  }
  p {
    font-weight: normal;
    text-align: center;
    margin: 0.5rem;
    line-height: 1.3;
    letter-spacing: 0.01rem;
  }
}
