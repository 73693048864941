#root {
  // height: 100%;
  width: 100%;
  min-height: 100%;

}

.App {
  width: 100%;
}


.app-wrapper {
  width: 100%;
  // min-height: 100%;
  min-height: 100vh;
  display: flex;
  // justify-content: center;
  align-items: center;
  transition: background-color 1s linear;
  flex-direction: column;
  user-select: none;
}

.app-inner {
  position: relative;
  max-width: 460px;
  width: 100%;
}

.text-english {
  letter-spacing: 0.01rem;
}

.text-english-line-height {
  line-height: 1.15;
}